import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { FaChevronRight, FaChevronDown } from 'react-icons/fa'; // Icons for expand/collapse

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL || 'https://bookhingebackend.onrender.com';

const FilterContainer = styled.div`
  padding: 24px;
  background: #FFFFFF;
  border-radius: 16px;
  margin-bottom: 24px;
  color: #111827;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  border: 1px solid #E5E7EB;
`;

const FilterTitle = styled.h2`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
`;

const FilterGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const CategoryButton = styled.button`
  background: ${props => props.active ? '#111827' : '#FFFFFF'};
  color: ${props => props.active ? '#FFFFFF' : '#111827'};
  border: 2px solid #111827;
  padding: 12px 24px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s ease;

  &:hover {
    background: ${props => props.active ? '#000000' : '#F9FAFB'};
  }
`;

const SubcategoryButton = styled.button`
  background: ${props => props.active ? '#4F46E5' : '#FFFFFF'};
  color: ${props => props.active ? '#FFFFFF' : '#111827'};
  border: 2px solid #4F46E5;
  padding: 8px 24px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 400;
  margin-left: 16px;
  transition: all 0.2s ease;

  &:hover {
    background: ${props => props.active ? '#3730A3' : '#E5E7EB'};
  }
`;

const ClearButton = styled.button`
  background: #FFFFFF;
  border: 2px solid #E5E7EB;
  color: #6B7280;
  padding: 12px 24px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  margin-top: 16px;
  transition: background 0.2s ease;

  &:hover {
    background: #F3F4F6;
  }
`;

const ExpandIcon = styled.span`
  display: flex;
  align-items: center;
  transition: transform 0.3s ease;
  transform: rotate(${props => (props.expanded ? '90deg' : '0deg')});
`;

const Filters = ({ selectedCategories, onCategoryChange }) => {
  const [categories, setCategories] = useState([]);
  const [expandedCategories, setExpandedCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // Fetch categories from backend
  const fetchCategories = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${BACKEND_API_URL}/books/categories`);
      if (Array.isArray(response.data)) {
        console.log("Fetched categories:", response.data); // Debugging
        setCategories(response.data);
      } else {
        throw new Error("Invalid data format");
      }
      setLoading(false);
    } catch (err) {
      console.error('Error fetching categories:', err);
      setError('Failed to load categories. Please try again later.');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleCategoryClick = (category) => {
    if (expandedCategories.includes(category)) {
      setExpandedCategories(prev => prev.filter(cat => cat !== category));
    } else {
      setExpandedCategories(prev => [...prev, category]);
    }
  };

  const handleSubcategoryClick = (subcategory) => {
    if (selectedCategories.includes(subcategory)) {
      onCategoryChange(selectedCategories.filter(cat => cat !== subcategory));
    } else {
      onCategoryChange([...selectedCategories, subcategory]);
    }
  };

  const clearFilters = () => {
    onCategoryChange([]);
  };

  if (loading) {
    return (
      <FilterContainer>
        <FilterTitle>Filter by Category</FilterTitle>
        <p>Loading categories...</p>
      </FilterContainer>
    );
  }

  if (error) {
    return (
      <FilterContainer>
        <FilterTitle>Filter by Category</FilterTitle>
        <p>{error}</p>
      </FilterContainer>
    );
  }

  return (
    <FilterContainer>
      <FilterTitle>Filter by Category</FilterTitle>
      <FilterGroup>
        {categories.map(cat => (
          <div key={cat.category}>
            <CategoryButton
              active={false} // Main categories are not selectable
              onClick={() => handleCategoryClick(cat.category)}
              aria-expanded={expandedCategories.includes(cat.category)}
              aria-controls={`subcategory-${cat.category}`}
            >
              {cat.category}
              {cat.subcategories.length > 0 && (
                <ExpandIcon expanded={expandedCategories.includes(cat.category)}>
                  {expandedCategories.includes(cat.category) ? <FaChevronDown /> : <FaChevronRight />}
                </ExpandIcon>
              )}
            </CategoryButton>
            {expandedCategories.includes(cat.category) && (
              <div id={`subcategory-${cat.category}`}>
                {cat.subcategories.map(sub => (
                  <SubcategoryButton
                    key={sub}
                    active={selectedCategories.includes(sub)}
                    onClick={() => handleSubcategoryClick(sub)}
                  >
                    {sub}
                  </SubcategoryButton>
                ))}
              </div>
            )}
          </div>
        ))}
      </FilterGroup>
      {selectedCategories.length > 0 && (
        <ClearButton onClick={clearFilters}>
          Clear Filters
        </ClearButton>
      )}
    </FilterContainer>
  );
};

export default Filters;